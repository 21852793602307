/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';

export const onRouteUpdate = ({ location, prevLocation }) => {
    // Disable cut, copy, paste, select, selectstart, drag, dragstart, and drop
    document.querySelector('body').addEventListener('cut copy paste select selectstart drag dragstart drop', function preventCopyingAndPastingPart1(e) {
        e.preventDefault();
    });
  
    // Disable mouse right-clicking
    document.querySelector('body').addEventListener('contextmenu', function preventCopyingAndPastingPart2(e) {
        return false;
    });
}